import axios from "axios";
import PageIndex from "../container/PageIndex";


const getBaseUrl = () => {
  if (process.env.REACT_APP_ENV === "PRODUCTION") {
    return {
      baseURL: process.env.REACT_APP_API_BASE_URL_LIVE,
      imageURL: process.env.REACT_APP_IMAGE_URL_LIVE
    };
  } else if (process.env.REACT_APP_ENV === "APPWORKDEMO") {
    return {
      baseURL: process.env.REACT_APP_API_BASE_URL_APPWORKDEMO,
      imageURL: process.env.REACT_APP_IMAGE_URL_APPWORKDEMO
    };
  } else {
    // Default to local server
    return {
      baseURL: process.env.REACT_APP_API_BASE_URL_LIVE,
      imageURL: process.env.REACT_APP_IMAGE_URL_LIVE
    };
  }
};

const { baseURL, imageURL } = getBaseUrl();
export const ImageURL = imageURL;

const DataService = axios.create({
  baseURL: baseURL,
});

DataService.interceptors.request.use(
  (config) => {
    config.headers.auth = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return error;
  }
);

export default DataService;

//Get Call
export const doGet = async (url) => {
  try {
    const response = await DataService.get(url);
    // if (response.data.status === 201 || 200) {
    //   PageIndex.toast.success(response.data.message);
    // }
    return response;
  } catch (error) {
    if (error) {
      PageIndex.toast.error(error.response.data.message);
    }
  }
};
export const doGetQueryParams = async (
  url,
  currentPage,
  limit,
  filterField
) => {
  try {
    const params = new URLSearchParams();
    for (const key in filterField) {
      params.append(`${key}`, filterField[key]);
    }
    // Additional parameters
    params.append("page", currentPage);
    params.append("perPage", limit);
    const response = await DataService.get(url, { params });
    return response;
  } catch (error) {
    if (error) {
      PageIndex.toast.error(error.response.data.message);
    }
  }
};
export const doGetParams = async (url, id) => {
  try {
    const response = await DataService.get(`${url}${id}`);
    return response;
  } catch (error) {
    if (error) {
      PageIndex.toast.error(error.response.data.message);
    }
  }
};

//Post Call 
export const doPost = async (url, data) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (response.data.status === 201 || 200) {
      PageIndex.toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};

//Post Call without
export const doPost2 = async (url, data) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};

//AuthPost Call
export const doAuthPost = async (url, data, navigate, path) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (response.data.status === 201 || 200) {
      PageIndex.toast.success(response.data.message);
      setTimeout(() => {
        navigate(path, { state: response.data.data._id });
      }, 1000);
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};

//Post FormData Call
export const doPostFormData = async (url, data, navigate, path) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.status === 201 || 200) {
      PageIndex.toast.success(response.data.message);
      setTimeout(() => {
        navigate(path);
      }, 1000);
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};
//Post FormData Call without navigate
export const doPostFormDataModal = async (url, data) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.status === 201 || 200) {
      PageIndex.toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};

//Update Call
export const doUpdate = async (url, id, data, navigate, path) => {
  try {
    const response = await DataService.post(`${url}${id}`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (response.data.status === 200 || 201) {
      PageIndex.toast.success(response.data.message);
      setTimeout(() => {
        navigate(path);
      }, 1000);
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};

//Delete by Params Call
export const doDelete = async (url, id) => {
  try {
    const response = await DataService.post(`${url}${id}`);
    if (response.data.status === 200) {
      PageIndex.toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};

//Delete by Body Call
export const doDeleteBody = async (url, id) => {
  try {
    const response = await DataService.post(url, { id: id });
    if (response.data.status === 200) {
      PageIndex.toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    PageIndex.toast.error(error.response.data.message);
  }
};
