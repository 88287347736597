export const Api = {
  adminLogin: "admin/admin-login",
  dashboardData: "admin/find-wallets",
  statisticData: "admin/wallet-chart",
  DATE_BAR_GRAPH : "admin/date-wallet-chart",
  ADD_EDIT_COMPAIN: "admin/add-edit-campaign",
  GET_COMPAIN_LIST: "admin/get-campaign",
  GET_WALLET_ACTIVITY_LIST: "admin/get-wallet-activity-log",
  GET_WALLET_CREATING_ACTIVITY_LIST: "admin/get-wallet-creating-log",
  GET_EXPORT_CSV: "admin/export-csv",
  GET_SPECIFIC_LIST: "admin/get-specific-campaign-new",
};
