import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import CommonPagination from "../../../../component/common/PaginationMaster";
import DateRangePickerMaster from "../../../../component/common/DateRangePickerMaster";
import SearchMaster from "../../../../component/common/SearchMaster";
import ExcelExportHelper from "../../../../component/common/ExcelExportHelper";
import axios from "axios"; // Import Axios library

function WalletRegisterImportActivity() {
    const [loading, setLoading] = useState(false);
    const [walletActivityData, setWalletActivityData] = useState([]);
    const [exportData, setExportData] = useState([]);
    // State for Search and date filter
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [searchValue, setSearchValue] = useState("");
    const [enableSheet, setEnableSheet] = useState(false);

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [paginationPerPage, setPaginationPerPage] = useState(10);
    const [pageDefault, setPageDefault] = useState(null);

    //Get list of WalletActivity.
    const getWalletCreatingActivityList = () => {
        setLoading(true);
        try {
            let walletBaseUrl = `${PageIndex.Api.GET_WALLET_CREATING_ACTIVITY_LIST}`;

            const newParams = new URLSearchParams();
            newParams.append(
                "page",
                pageDefault !== null ? pageDefault : currentPage
            );
            newParams.append("size", paginationPerPage);

            if (searchValue) {
                newParams.append("search", searchValue);
            }
            if (
                selectedDate?.length &&
                selectedDate[0] !== null &&
                selectedDate[1] !== null
            ) {
                newParams.append("from", selectedDate[0].format("YYYY-MM-DD") ?? "");
                newParams.append("to", selectedDate[1].format("YYYY-MM-DD") ?? "");
            }

            // Append query string only if searchData or filterDate is provided
            if (
                searchValue ||
                (selectedDate[0] !== null && selectedDate[1] !== null) ||
                currentPage ||
                paginationPerPage
            ) {
                walletBaseUrl += `?${newParams.toString()}`;
            }

            PageIndex.doGet(walletBaseUrl).then((res) => {
                if (res?.status === 200) {
                    let walletActivityModify = res?.data?.data?.map((item) => {
                        return {
                            _id: item?._id,
                            walletAction: item.walletAction?.toUpperCase() || "-",
                            appType:
                                item.appType == 0 ? "Android" : item.appType == 1 ? "IOS" : "-",
                            walletAddress: item.walletAddress || "-",
                            createdAt: item.createdAt
                                ? Index.moment(item.createdAt)
                                    .utc("+05:30")
                                    .format("DD-MM-YYYY hh:mm A")
                                : "-",
                        };
                    });
                    setCurrentPage(res?.data?.currentPage);
                    setTotalRecordCount(res?.data?.totalWalletActivityLogs);
                    setPageDefault(null);
                    setWalletActivityData(walletActivityModify);
                    // setEnableSheet(true);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    //Page load getWalletActivity data.
    useEffect(() => {
        getWalletCreatingActivityList();
    }, [selectedDate, searchValue, paginationPerPage, currentPage]);

    //Get list of Download csv.
    const downloadWalletCreatingCsv = () => {
        setEnableSheet(true);
        try {
            let walletBaseUrl = `${PageIndex.Api.GET_EXPORT_CSV}`;

            const newParams = new URLSearchParams();

            if (searchValue) {
                newParams.append("search", searchValue);
            }
            if (
                selectedDate?.length &&
                selectedDate[0] !== null &&
                selectedDate[1] !== null
            ) {
                newParams.append("from", selectedDate[0].format("YYYY-MM-DD") ?? "");
                newParams.append("to", selectedDate[1].format("YYYY-MM-DD") ?? "");
            }

            // Append query string only if searchData or filterDate is provided
            if (
                searchValue ||
                (selectedDate[0] !== null && selectedDate[1] !== null) ||
                currentPage ||
                paginationPerPage
            ) {
                walletBaseUrl += `?${newParams.toString()}`;
            }

            PageIndex.doGet(walletBaseUrl).then((res) => {
                if (res?.status === 200) {
                    let walletActivityModify = res?.data?.data?.map((item) => {
                        return {
                            //   _id: item?._id,
                            WALLETADDRESS: item.walletAddress || "-",
                            APPTYPE:
                                item.appType == 0 ? "Android" : item.appType == 1 ? "IOS" : "-",
                                WALLETACTION: item.walletAction?.toUpperCase() || "-",
                                DATE: item.createdAt
                                ? Index.moment(item.createdAt)
                                    .utc("+05:30")
                                    .format("DD-MM-YYYY hh:mm A")
                                : "-",
                        };
                    });
                    setExportData(walletActivityModify);
                    setEnableSheet(false);
                } else {
                    setEnableSheet(false);
                }
            });
        } catch (error) {
            console.log(error);
            setEnableSheet(false);
        }
    };

    const shortcutsItems = [{ label: "Reset", getValue: () => [null, null] }];

    return (
        <>
            <Index.DynamicTitle title="presale-wraper" />
            <Index.Box className="presale-wraper">
                <Index.Box className="res-cus-table">
                    <Index.Box className="offering-cus-table">
                        <Index.Box className="offering-table-head tryed">
                            <Index.Box className="title-main">
                                <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                >
                                    Wallet Creating Activity Logs
                                </Index.Typography>
                            </Index.Box>

                            <Index.Box className="date-search-wrapper">
                                {/* DateRange Comman Components */}
                                <DateRangePickerMaster
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    shortcutsItems={shortcutsItems}
                                />

                                {/* SearchMaster Comman Components */}
                                <SearchMaster setSearchValue={setSearchValue} />

                                {walletActivityData?.length ? (
                                    <>
                                        <Index.Box className="common-button grey-button">
                                            <Index.Button
                                                variant="contained"
                                                onClick={() => {
                                                    downloadWalletCreatingCsv();
                                                }}
                                            >
                                                Export
                                            </Index.Button>
                                        </Index.Box>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {!enableSheet && exportData?.length ? (
                                    <>
                                        <Index.Box className="common-button grey-button">
                                            <ExcelExportHelper
                                                data={exportData}
                                                loading={loading}
                                                sheetName={"Wallet_Register_Activity_Logs"}
                                                setExportData={setExportData}
                                            />
                                        </Index.Box>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    <Index.Box className="border-round-table">
                        <Index.TableContainer
                            component={Index.Paper}
                            className="table-container"
                        >
                            <Index.Table
                                aria-label="simple table"
                                className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
                            >
                                <Index.TableHead className="gradient-bg">
                                    <Index.TableRow>
                                        <Index.TableCell align="left">
                                            WalletAddress
                                        </Index.TableCell>
                                        <Index.TableCell align="left">AppType</Index.TableCell>
                                        <Index.TableCell align="left">WalletAction</Index.TableCell>
                                        <Index.TableCell align="left"> Date </Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                {loading ? (
                                    //Loading Components
                                    <Index.Loading />
                                ) : (
                                    <Index.TableBody>
                                        {walletActivityData?.length ? (
                                            walletActivityData.map((item, index) => (
                                                <Index.TableRow key={item?._id}>
                                                    <Index.TableCell>
                                                        {item?.walletAddress}
                                                    </Index.TableCell>
                                                    <Index.TableCell>{item?.appType}</Index.TableCell>
                                                    <Index.TableCell>
                                                        {item?.walletAction}
                                                    </Index.TableCell>
                                                    <Index.TableCell>{item?.createdAt}</Index.TableCell>
                                                </Index.TableRow>
                                            ))
                                        ) : (
                                            //no data found comman component.
                                            <Index.NoDataFound />
                                        )}
                                    </Index.TableBody>
                                )}
                            </Index.Table>
                        </Index.TableContainer>
                    </Index.Box>

                    {/* Pagination Comman Components */}
                    <CommonPagination
                        paginationPerPage={paginationPerPage}
                        setPaginationPerPage={setPaginationPerPage}
                        setPageDefault={setPageDefault}
                        totalRecordCount={totalRecordCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Index.Box>
            </Index.Box>
        </>
    );
}

export default WalletRegisterImportActivity;
