import React, { useState } from "react";
import Index from "../../../Index";
import { ethers } from "ethers";
import presaleAbi from "../home/presaleAbi.json";
import { preSaleAddress } from "../../AddressHelper";
import { NumbersOutlined } from "@mui/icons-material";
import PageIndex from "../../../PageIndex";
import IconCopy from "../../../../assets/images/svg/copyIcon";
const Foundary = () => {
  const [data, setData] = useState([]);
  const [tokensData, setTokensData] = useState();
  const copiedSuccess = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    PageIndex.toast.success("Copied Successfully", {
      toastId: "1",
    });
  };
  const allReadData = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://bsc-dataseed1.binance.org/"
      );
      const contract = new ethers.Contract(
        preSaleAddress,
        presaleAbi,
        provider
      );
      const getDetails = await contract.getAllFounderData();
      const getData = await contract.getTokonomicsData();

      setTokensData({
        totalPLTToken: getData[4].totalPhaseToken.toString() / 10 ** 18,
        totalSupply: getData[4].totalSuppliedToken.toString() / 10 ** 18,
        totalRemaining:
          (getData[4].totalPhaseToken.toString() -
            getData[4].totalSuppliedToken.toString()) /
          10 ** 18,
      });
      const optimizedData = getDetails.map((element) => {
        const getTime = (time) => {
          const timestamp = time;
          const date = new Date(timestamp * 1000);
          const year = date.getFullYear().toString().slice(-2);
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        };
        return {
          phaseName: element.phaseName,
          beneficiary: element.beneficiary,
          tokens: element.pltToken?.toString() / 10 ** 18,
          startTime: getTime(element.startTime),
        };
      });
      setData(optimizedData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useState(() => {
    allReadData();
  }, []);
  return (
    <>
      <Index.Box className="presale-wraper">
        <Index.Box className="stat-box">
          <Index.Box className="stat">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {tokensData ? tokensData?.totalPLTToken : 0}
              </Index.Typography>
              <Index.Typography component="p">Total Token</Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="stat unlock">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {tokensData ? tokensData?.totalSupply : 0}
              </Index.Typography>
              <Index.Typography component="p">
                Total Supplied Token
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="stat available">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {tokensData ? Number(tokensData?.totalRemaining).toFixed(6) : 0}
              </Index.Typography>
              <Index.Typography component="p">
                Total Remaining Token
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="cus-table offering-list">
          <Index.Box className="cus-table-header d-flex">
            <Index.Box className="cus-th">No</Index.Box>
            <Index.Box className="cus-th"> Phase Name</Index.Box>
            <Index.Box className="cus-th">Address</Index.Box>
            <Index.Box className="cus-th">Total Token</Index.Box>
            <Index.Box className="cus-th">Date</Index.Box>
          </Index.Box>

          {data.map((element, index) => (
            <Index.Box className="cus-table-body">
              <Index.Box className="cus-tr d-flex">
                <Index.Box className="cus-td">
                  <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                    <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th-text"
                        sx={{
                          color: "white",
                        }}
                      >
                        {index + 1}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="cus-td">
                  <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                    <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th-text"
                        sx={{
                          color: "white",
                        }}
                      >
                        {element.phaseName}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="cus-td">
                  <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                    <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th-text"
                        sx={{
                          color: "white",
                        }}
                      >
                        {element.beneficiary.slice(0, 5)}...
                        {element.beneficiary.slice(-4)}
                      </Index.Typography>
                      <Index.Button
                        className="copy-btn"
                        onClick={() => copiedSuccess(element?.beneficiary)}
                      >
                        <IconCopy className="copy-icon" />
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="cus-td">
                  <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                    <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th-text"
                        sx={{
                          color: "white",
                        }}
                      >
                        {element.tokens.toFixed(2)}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="cus-td">
                  <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                    <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th-text"
                        sx={{
                          color: "white",
                        }}
                      >
                        {element.startTime}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ))}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Foundary;
