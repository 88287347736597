import { createAsyncThunk } from "@reduxjs/toolkit";
import PageIndex from "../../container/PageIndex";

export const loginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
  try {
    const response = await PageIndex.doPost(PageIndex.Api.adminLogin, data);
    return response?.data;
  } catch (error) {}
});

export const getDashboardData = createAsyncThunk(
  "admin/getDashboardData",
  async () => {
    try {
      const response = await PageIndex.doGet(PageIndex.Api.dashboardData);
      return response?.data;
    } catch (error) {}
  }
);

// api call for statistic data fetching
export const getStatisticData =  async (data) => {
  try {
    const response = await PageIndex.doPost2(PageIndex.Api.statisticData,data);
    // console.log(response?.data, "statistic data");
    return response?.data;
  } catch (error) {
    
  }
}

// api call for getDateFilterGraphData data fetching
export const getDateFilterGraphData =  async (finalDate) => {
  try {
    const urlencoded = new URLSearchParams();
    urlencoded.append("fromDate", finalDate.fromDate);
    urlencoded.append("toDate", finalDate.toDate);
    const response = await PageIndex.doPost2(PageIndex.Api.DATE_BAR_GRAPH,urlencoded);
    return response?.data;
  } catch (error) {
    console.log("error",error);
  }
}