import React from 'react';
import Index from '../../container/Index';

const CommonPagination = ({ 
    paginationPerPage, 
    setPaginationPerPage, 
    setPageDefault, 
    totalRecordCount, 
    currentPage, 
    setCurrentPage 
  }) => {
    return (
      <Index.Box className="pagination-design flex-end">
        <Index.Stack spacing={2}>
          <Index.Box className="pagination-count">
            <Index.Box className="input-design-div with-border">
              <Index.Select
                fullWidth
                value={paginationPerPage}
                onChange={(e) => {
                  setPaginationPerPage(e.target.value);
                  setPageDefault(1);
                }}
              >
                <Index.MenuItem value={10}>10</Index.MenuItem>
                <Index.MenuItem value={25}>25</Index.MenuItem>
                <Index.MenuItem value={50}>50</Index.MenuItem>
                <Index.MenuItem value={100}>100</Index.MenuItem>
              </Index.Select>
            </Index.Box>
            { (
              <>

                <Index.Pagination
                  count={Math.ceil(totalRecordCount / paginationPerPage)}
                  page={currentPage}
                  onChange={(e, value) => {
                    setCurrentPage(value);
                  }}
                />
              </>
            )}
          </Index.Box>
        </Index.Stack>
      </Index.Box>
    );
  };
  
  export default CommonPagination;